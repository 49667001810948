<template></template>
<script>
export default {
  name: 'text-chat',
  data() {
    return {
      door: false,
      status: this.value,
      ws: null,
    }
  },
  props: {
    watch:{
        value(e){
            this.status = e
        }
    },
    user_id: {
      type: String
    },
    app_id: {
      type: String
    },
    value: {
      type: Boolean
    }
  },
  watch: {
    value(e) {
      this.status = e
    }
  },
  mounted() {
    this.onConnect()
  },
  methods: {
    onConnect() {   
      console.log(this.user_id) 
      if (!this.user_id) {
        return
      }

      //建立连接
      let ws = new WebSocket("wss://team-chat.learningfirst.cn:9000");
      ws.onerror = (err) => {
        console.log(err)
      };
      this.ws = ws

      //对ws进行设置

      //成功建立的操作
      ws.onopen = () => {
        console.log('APP_ID:'+this.app_id)
        console.log('USER_ID:'+this.user_id)
        console.log('GROUP_ID:'+this.group_id)
        this.$message({
            message:'Connection Success',
            type:'success',
            duration:5000,
            showClose:true
        })
        this.changeStatus(true)
        ws.send(`user&${'Economics'}&${this.user_id}`);
      };
      //关闭连接的操作
      ws.onclose = () => {
        this.changeStatus(false)
      }
      //接收到消息的操作
      ws.onmessage = (e) => {
        if(typeof e.data === 'string'){
            this.$message({
                message:'系统消息：'+e.data,
                duration:5000,
                showClose:true
            })
        }
      };

    },
    offConnect() {
      if (this.ws) {
        this.ws.close();
        this.changeStatus(false)
      }
    },
    changeStatus(bool) {
      this.status = bool
      this.$emit('input', bool)
    },
  },
  destroyed() {
    console.log('Socket Closed')
    this.offConnect()
    clearInterval(this.statusTimer)
  }
}
</script>
<template>
  <div class="exam-root">
    <div class="loadingView" v-if="showloadingView">
      <img src="../../assets/exam_ready_bg.png" class="loadingImg">

      <div class="hourStyle">
        {{hourLab}}
      </div>

      <div class="minuteStyle">
        {{minuteLab}}
 
      </div>

      <div class="secondStyle">
        {{secondLab}}
      </div>

      <div class="dianStyle">
        :
      </div>

      <div class="dianStyle1">
        :
      </div>

      <div class="loadingInfoStyle">

        <span style="color: red;">关于倒计时的说明</span><br><br>
        
        
        为避免进场拥堵，请耐心等待一个随机的缓冲时间。缓冲结束后，您可正常开始考试。(等待时间不计入考试时间）<br>
        To avoid entry congestion, please be patient and wait for a random buffer time. <br>
        After the buffer is over, you can start the exam normally. (Waiting time is not counted as exam time)<br><br>


        <span style="color: red;">FAQ：</span><br><br>

        <div class="loadingInfoStyleMore">
          1.考试是否可以提前交卷？<br>
            开考后30分钟可以提前交卷。<br><br>

          2.因误触关闭页面怎么办？答题记录会不会没保存？<br>
            如误触关闭页面，可回到首页后再次进入考试；选手所在的考试环境中，网络正常流畅的状态下，答题记录将实时保存，再次进入考试后请第一时间检查每道题的答题状态。<br><br>

          3.考试过程中是否可以去洗手间？<br>
            考试过程中不允许去洗手间。<br>

        </div>
      </div>

    </div>
    <TextChat v-if="isConnect" :user_id="user_id" />
    <div class="exam-top">
      <Invigilate
        v-if="!DEBUG_DEV && signStatus"
        v-model="status"
        :template_id="template_id"
        @invigilateCallback="invigilateCallback"
        @visibleChange="refreshVisible"
        style="margin-right: 40px"
      ></Invigilate>
      <div style="color: white; margin-right: 30px">
        {{ getShowWord("exchange_screen_counts") }}: {{ screenChangeCount }}
        <i @click="showExchangeTip" class="el-icon-info"></i>
        <!-- {{getShowWord('exchange_screen_counts')}}: {{ screenChangeCount + ' / ' + change_screen_num }} -->
      </div>
      <div class="exam-count-down">
        {{ countDownTimes }}
      </div>
      <div class="exam-submit" @click="doExchangeLang">
        {{ getShowWord("exchange_lang") }}
      </div>
      <div class="exam-submit" @click="doSubmitAnswer">
        {{ getShowWord("submit_exam") }}
      </div>
    </div>
    <div class="exam-content" id="exam-content">
      <div class="exam-empty-width-view" />
      <div
        style="flex: 1; display: flex; flex-direction: column; overflow: hidden"
      >
        <div class="exam-empty-height-view" />
        <div style="width: 100%; flex: 1; overflow: hidden">
          <div v-if="canExam  && havePicUrl == true" class="exam-content-page">
            <div style="width: 100%; flex: 1; overflow-y: scroll" id="exam-image">
              <div class="exam-image">
                <PDF ref="pdfView" @pdfCallback="pdfCallback" :parentReloadPic="parentReloadPic" :parentShowBigPic="parentShowBigPic"></PDF>
              </div>
            </div>
            <div class="exam-page-bottom">
              <div class="exam-page-bottom">
                <div class="exam-page-bottom-text" @click="showPreImage">
                  {{ getShowWord("page_pre") }}
                </div>
                <div class="exam-page-bottom-text">
                  {{
                    examImageSize > 0
                      ? currentSelectedImageIndex  + "/" + examImageSize
                      : "0/0"
                  }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextImage">
                  {{ getShowWord("page_next") }}
                </div>
              </div>
              <div class="exam-goto-page" @click="beforeShowSelectImageDialog">
                {{ getShowWord("gotoPage") }}
              </div>

              <div class="exam-goto-page" style="background-color: #00d523" @click="reloadPage()">
                Reload 
              </div>

              <div class="exam-goto-page" @click="enlarge()">
                Enlarge
              </div>

            </div>
          </div>
          <div v-if="canExam && havePicUrl == false && section_list_instruction != ''" class="exam-content-nourl-page" >

            <div class="ql-snow ql-editor" style="font-size: 18px;font-weight: 500;cursor: none;" v-html="section_list_instruction"></div>

          </div>
        </div>
      </div>
      <div class="exam-empty-width-view" />
      <div style="flex: 1; display: flex; flex-direction: column">
        <div class="exam-empty-height-view" />
        <div
          style="
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            v-if="canExam"
            class="exam-content-page"
            style="background: #eff2ff"
          >
            <div style="flex: 1; width: 100%; height: 100%">
              <div class="exam-question-content;">
                <div style="margin: 20px">
                  <div class="questionTitle">
                    <div
                      v-html="currentSelectedQuestion['question_question']"
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionA &&
                      currentSelectedQuestion.question_optionA != ''
                    "
                  >
                    <el-radio
                      @change="saveAnswer('A', currentSelectedQuestion)"
                      :value="currentSelectedQuestion.choice"
                      label="A"
                    >
                      <span class="optionTitle">A</span>
                    </el-radio>
                    <div
                      class="div_p"
                      v-html="currentSelectedQuestion.question_optionA"
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionB &&
                      currentSelectedQuestion.question_optionB != ''
                    "
                  >
                    <el-radio
                      @change="saveAnswer('B', currentSelectedQuestion)"
                      :value="currentSelectedQuestion.choice"
                      label="B"
                    >
                      <span class="optionTitle">B</span>
                    </el-radio>
                    <div
                      class="div_p"
                      v-html="currentSelectedQuestion.question_optionB"
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionC &&
                      currentSelectedQuestion.question_optionC != ''
                    "
                  >
                    <el-radio
                      @change="saveAnswer('C', currentSelectedQuestion)"
                      :value="currentSelectedQuestion.choice"
                      label="C"
                    >
                      <span class="optionTitle">C</span>
                    </el-radio>
                    <div
                      class="div_p"
                      v-html="currentSelectedQuestion.question_optionC"
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionD &&
                      currentSelectedQuestion.question_optionD != ''
                    "
                  >
                    <el-radio
                      @change="saveAnswer('D', currentSelectedQuestion)"
                      :value="currentSelectedQuestion.choice"
                      label="D"
                    >
                      <span class="optionTitle">D</span>
                    </el-radio>
                    <div
                      class="div_p"
                      v-html="currentSelectedQuestion.question_optionD"
                    ></div>
                  </div>
                  <div
                    class="option"
                    v-if="
                      currentSelectedQuestion.question_optionE &&
                      currentSelectedQuestion.question_optionE != ''
                    "
                  >
                    <el-radio
                      @change="saveAnswer('E', currentSelectedQuestion)"
                      :value="currentSelectedQuestion.choice"
                      label="E"
                    >
                      <span class="optionTitle">E</span>
                    </el-radio>
                    <div
                      class="div_p"
                      v-html="currentSelectedQuestion.question_optionE"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="exam-page-bottom">
              <div class="exam-page-bottom">
                <div class="exam-page-bottom-text" @click="showPreQuestion">
                  {{ getShowWord("question_pre") }}
                </div>
                <div class="exam-page-bottom-text">
                  {{
                    examQuestionSize > 0
                      ? currentSelectedQuestionIndex +
                        1 +
                        "/" +
                        examQuestionSize
                      : "0/0"
                  }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextQuestion">
                  {{ getShowWord("question_next") }}
                </div>
              </div>
              <div class="exam-menu" @click="showMenu">
                {{ getShowWord("menu") }}
              </div>
              <div
                class="exam-menu"
                style="width: 6vw; background-color: #00d523"
                @click="showMessage"
              >
                <div class="redTip" v-if="showRedTip"></div>
                {{ getShowWord("question") }}
              </div>
              <div class="exam-menu" @click="showQADrawer = true">FAQ</div>
            </div>
          </div>
        </div>
      </div>
      <div class="exam-empty-width-view" />
    </div>
    <el-dialog
      :visible.sync="showSubmitAnswerDialog"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showSubmitAnswerDialogStyle"
    >
      <div class="submitAnswer-root">
        <div style="position: absolute; right: 1vw; top: 1vw">
          <img
            src="../../assets/close_1.png"
            style="cursor: pointer; width: 43px; height: auto"
            @click="showSubmitAnswerDialog = false"
          />
        </div>
        <div
          style="
            width: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 5vh;
          "
        >
          <div style="color: white; font-size: 1.872vw; font-weight: 600">
            {{ getShowWord("want_to_submit_answer") }}
          </div>
          <div
            style="
              margin-top: 6vh;
              display: flex;
              justify-content: center;
              flex-direction: row;
              height: 5vh;
              line-height: 5vh;
            "
          >
            <div
              @click="showSubmitAnswerDialog = false"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                color: #0e409f;
                width: 9vw;
                background-color: #b1ccff;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_cancel") }}
            </div>
            <div
              @click="uploadAnswers"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                margin-left: 40px;
                color: #0e409f;
                width: 9vw;
                background-color: white;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_confirm") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-drawer
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      :visible.sync="showDrawer"
      direction="rtl"
      :withHeader="false"
      size="60%"
      custom-class="showMenuDialogStyle"
    >
      <div class="menu-content">
        <div style="margin-top: 100px">
          <img
            src="../../assets/drawer-left.png"
            style="cursor: pointer; width: 28px; height: auto"
            @click="showDrawer = false"
          />
        </div>
        <div class="drawer-question-list" style="margin-left: -2px">
          <div style="padding-top: 30px; padding-bottom: 15px">
            <p
              style="
                letter-spacing: 3px;
                color: white;
                font-size: 20px;
                font-weight: bold;
                font-family: Montserrat-SemiBold;
              "
            >
              {{ drawerAnswerInfo }}
            </p>
          </div>
          <div style="width: 100%; height: 100%; overflow-y: scroll">
            <el-tabs v-model="activeName" stretch="true">
              <el-tab-pane :label="getShowWord('all_questions')" name="first">
                <div>
                  <div
                    v-for="(item, index) in drawerQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(index)"
                  >
                    <div
                      :class="{
                        'drawer-question-item': true,
                        selected: item.choice !== '',
                      }"
                    >
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="
                            margin-left: 10px;
                            text-align: left;
                            font-size: 14px;
                          "
                        ></div>
                      </div>
                      <div class="drawer-answered" v-if="item.choice !== ''">
                        {{ getShowWord("answered") }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="getShowWord('not_answer')" name="second">
                <div>
                  <div
                    v-for="(item, index) in drawerNoChoiceQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(item['parentIndex'])"
                  >
                    <div :class="{ 'drawer-question-item': true }">
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="margin-left: 10px; text-align: left"
                        ></div>
                      </div>
                      <!--                      <div class="drawer-answered" v-if="item.choice !==''">-->
                      <!--                        已做答-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showBigPicDialog" width="100%">
      <img :src="currentSelectedPicPath" />
    </el-dialog>
    <el-dialog
      :visible.sync="showAnswerResultDialog"
      width="100%"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showAnswerResultDialogStyle"
    >
      <div class="answer-result-root">
        <div class="awardTitle">Congratulations</div>
        <div class="scoreLab2">{{ "/   " + answerResultDta.full_score }}</div>
        <div class="scoreLab1">{{ answerResultDta.total_score }}</div>
        <div class="awardTitle1">{{ answerResultDta.application_event }}</div>
        <div class="awardLine"></div>
        <div class="awardSubTitle">
          You have learnt and mastered the following knowledge, concepts and
          theories:
        </div>
        <div class="box">
          <div
            v-for="(item, index) in answerResultDta.achievements"
            :key="index"
            class="child"
          >
            <img
              class="infoImg"
              :src="require('../../assets/selectInfoImage.png')"
            />
            <div class="infoStyle">
              {{ "&nbsp;&nbsp;" + item + "&nbsp;&nbsp;" }}
            </div>
          </div>
        </div>
        <div @click="closeView" class="returnStyle">Return Home</div>
      </div>
    </el-dialog>

    <el-drawer
      title="咨询消息"
      :visible.sync="messageDrawer"
      direction="ltr"
      size="500px"
      :before-close="handleClose"
    >
      <!-- <span>消息列表</span> -->

      <div class="messageList">
        <div class="inputStyle">
          <el-select type="text" class="inputSelect" v-model="messageType">
            <el-option value="学术问题" label="学术问题"></el-option>
            <el-option value="流程问题" label="流程问题"></el-option>
            <el-option value="系统操作问题" label="系统操作问题"></el-option>
            <el-option value="数据问题" label="数据问题"></el-option>
            <el-option value="奖项证书问题" label="奖项证书问题"></el-option>
            <el-option value="其他问题" label="其他问题"></el-option>
          </el-select>

          <el-input v-model="messageStr" class="inputStyle1"></el-input>
          <el-button class="sendStyle" @click="sendMessage">提交</el-button>
        </div>

        <div style="margin-top: 20px; height: 20px"></div>

        <div
          v-for="(x, index) in messageList"
          :key="index"
          class="messageDetail"
        >
          <div style="color: #001d9f; font-size: 15px">
            {{ x.type }}
          </div>
          <div style="color: #00359f; font-size: 13px; margin-top: 10px">
            {{ x.message_content }}
          </div>

          <div
            v-if="x.reply_content != ''"
            style="color: #00359f; font-size: 13px; margin-top: 10px"
          >
            {{ "官方回复：" + x.reply_content }}
          </div>

          <div
            style="background-color: #00359f; height: 1px; margin-top: 10px"
          ></div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showGotoPageDialog" title="Tips" width="30%">
      <div>
        {{ getShowWord("gotoPage") }}
        <el-input-number
          v-model="gotoImageIndex"
          :min="1"
          :max="examImageSize"
          @change="handleGotoImagChanged"
        ></el-input-number>
        / {{ examImageSize }}
      </div>
      <div style="margin-top: 25px">
        <el-button size="small" type="primary" @click="gotoSelectedImage">
          {{ getShowWord("btn_confirm") }}</el-button
        >
        <el-button
          size="small"
          style="margin-left: 20px"
          @click="showGotoPageDialog = false"
        >
          {{ getShowWord("btn_cancel") }}</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      title="FAQ信息"
      :visible.sync="showQADrawer"
      direction="rtl"
      size="40%"
    >
      <div style="text-align: left; margin: 15px">
        <QA />
      </div>
    </el-drawer>
    
  </div>
</template>

<script>
import {
  getExerciseTemplateByStudent,
  getMyMessages,
  getTestVisibleChangeCount,
  sendMessage,
  submitAnswer,
  checkIsUploadTest,
  uploadTestHeartBeat,
  studentSignWithTemplate,
  uploadAnswersV3,
} from "../../api/index";
import "../../assets/common/font.css";
import {
  getAppId,
  getProjectCode,
  getUserId,
  getUserIP,
  getTestEvent,
} from "../../utils/store";
import Invigilate from "./Invigilate";
import QA from "./QA";
import scroll from "../../utils/scroll";
import TextChat from "../CommonChat/txt.vue";
import { Loading } from 'element-ui';
import PDF from '../pdf/PDF.vue'

export default {
  name: "StudentExam",
  components: {
    Invigilate,
    QA,
    TextChat,
    PDF
  },
  watch: {
    status(newStatus, oldStatus) {
      

      if (this.invigilateStatus){
        this.canExam = true;
        this.fetchData();
      }else{
        if (!newStatus) {
          //禁止做题目了
          this.canExam = false;
          console.log("---sorry--");
          this.clearCountDown();
          //停止一切
        } else {
          console.log("---yes --");
          this.canExam = true;
          this.fetchData();
        }
      }

    },
  },
  data() {
    return {
      parentShowBigPic:0,
      parentReloadPic:0,
      section_list_instruction:'',
      havePicUrl:false,
      questionAnswers:{},
      showQADrawer: false,
      gotoImageIndex: 1,
      showGotoPageDialog: false,
      activeName: "first",
      canExam: true,
      answerResultDta: {},
      showAnswerResultDialog: false,
      showDrawer: false,
      messageDrawer: false,
      showSubmitAnswerDialog: false,
      user_id: "",
      template_id: "",
      test_subject: "",
      countDownThread: undefined,
      sections: [],
      countDownTimes: "00:00:00",
      test_event: getTestEvent(),
      status: false,
      exam_images: [],
      currentSelectedImageIndex: 1,
      examImageSize: 0,
      signStatus: false,
      currentSelectedQuestionIndex: 0,
      questionList: [],
      drawerQuestionList: [],
      drawerChoiceQuestionList: [],
      drawerNoChoiceQuestionList: [],
      examQuestionSize: 0,
      currentSelectedQuestion: {
        question_id: "",
        question_index: "",
        question_question: "",
        question_optionA: "",
        question_optionB: "",
        question_optionC: "",
        question_optionD: "",
        question_optionE: "",
        choice: "",
      },
      showBigPicDialog: false,
      currentSelectedPicPath: "",
      app_id: "",
      test_type:'',
      deviceStatus: 0,
      DEBUG_DEV: false,
      drawerAnswerInfo: "",
      project_code: "",
      ip: "",
      messageList: [],
      messageType: "请选择问题类型",
      messageStr: "",
      timer: "",
      showRedTip: false,
      screenChangeCount: 0,
      heartBeatTimer: undefined,
      currentLang: "中文",
      WORDS: {
        中文: {
          exchange_lang: "English",
          page_pre: "上一页",
          page_next: "下一页",
          question_pre: "上一题",
          question_next: "下一题",
          menu: "目录",
          question: "咨询",
          submit_exam: "提交试卷",
          count_down: "倒计时",
          exchange_screen_counts: "切屏次数",
          btn_cancel: "取消",
          btn_confirm: "确定",
          want_to_submit_answer: "是否提交试卷",
          all_questions: "所有",
          not_answer: "未答",
          answered: "已答",
          gotoPage: "跳转到",
        },
        英文: {
          exchange_lang: "中文",
          page_pre: "Previous Page",
          page_next: "Next Page",
          question_pre: "Previous Question",
          question_next: "Next Question",
          menu: "Menu",
          question: "Messages",
          submit_exam: "Submit Answers",
          count_down: "Count Down",
          exchange_screen_counts: "ExchangeScreenCounts",
          btn_cancel: "Cancel",
          btn_confirm: "Confirm",
          want_to_submit_answer: "Confirm Submit Answers?",
          all_questions: "All Questions",
          not_answer: "Not Answers",
          answered: "Answered",
          gotoPage: "Go To",
        },
      },
      showAnswerResultClick: false,
      forceShutDown: false,
      isConnect: false,
      showloadingView: false,
      countDownInternal: undefined,
      hourLab: '00',
      minuteLab: '00',
      secondLab: '00',
      invigilateStatus : false,
      remainStamp: 0,

    };
  },
  created() {
    window.addEventListener("offline", this.networkErrorCallback);
    window.addEventListener("online", this.networkSuccessCallback);
  },
  async mounted() {
    this.project_code = getProjectCode();
    this.app_id = getAppId();
    this.template_id = this.$route.query.template_id;
    this.invigilateStatus = this.$route.query.invigilateStatus;

    this.user_id = getUserId();

    //先签到
    let result = await this.studentSignIn();
    if (result) {
      if (this.invigilateStatus == 'true'){
        this.signStatus = false;
      }else{
        this.signStatus = true;
      }
    } else {
      return;
    }


    //打开socket
    this.isConnect = true;
    if (this.DEBUG_DEV) {
      this.fetchData();
    }
    getUserIP((ip) => {
      console.log(2222, ip);
      this.ip = ip;
    });


    if (this.invigilateStatus == 'true'){
        this.signStatus = false;
        this.fetchData();
    }else{
        this.refreshVisible();
    }

    this.getMyMessages();

    // this.timer = setInterval(this.showRedMessage, 5000);
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.onBrowserBack, false);
    // }
    this.checkStudentExam();

    setTimeout(() => {
      this.pushHistory();
      window.addEventListener("popstate", this.goBack, false);
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    }, 1000);
  },
  methods: {
    enlarge(){
      this.parentShowBigPic++
    },
    reloadPage(){
      this.parentReloadPic++
    },
    pdfCallback(numbers){
      this.examImageSize  = numbers
    },
    reLoadImage(url){
      var time =  new Date().getTime();
      let new_url = url +"?"+ time
      this.exam_images[this.currentSelectedImageIndex] =new_url
    },
    pushHistory() {
      history.pushState(null, null, document.URL);
    },
    networkSuccessCallback() {
      this.$message.success("网络已连接!");
    },
    networkErrorCallback() {
      this.$message.error("网络异常，请检查网络!");
    },
    handleGotoImagChanged(value) {
      //图片不需要跟着一起切换
      // this.currentSelectedImageIndex = this.gotoImageIndex-1
    },
    beforeShowSelectImageDialog() {
      this.gotoImageIndex = this.currentSelectedImageIndex + 1;
      this.showGotoPageDialog = true;
    },
    gotoSelectedImage() {
      if (this.$refs.pdfView.checkIsLoadingPdf()){
        return;
      }
      this.resetViewPosition();
      var index  = this.gotoImageIndex;
      this.$refs.pdfView.gotoTargetPage(index)
      this.currentSelectedImageIndex= index
      this.showGotoPageDialog = false;
    },

    doExchangeLang() {
      if (this.currentLang === "中文") {
        this.currentLang = "英文";
      } else {
        this.currentLang = "中文";
      }
    },
    getShowWord(value) {
      return this.WORDS[this.currentLang][value];
    },

    studentSignIn() {
      return new Promise((resolve, reject) => {
        studentSignWithTemplate(
          this.user_id,
          this.template_id,
          this.project_code
        ).then((res) => {
          var result = res.data.data;
          var begin_time = result.begin_time;
          var end_time = result.end_time;
          var test_duration = result.test_duration;

          console.log("begin_time :" + begin_time);
          console.log("end_time :" + end_time);
          console.log("test_duration :" + test_duration);

          var currentTime = new Date().getTime() / 1000;

          console.log("currentTime :" + currentTime);
          // if(currentTime<=begin_time){
          //   this.$router.go(-1)
          //   this.$message.error('考试时间还没有到！')
          //   return
          // }
          if (currentTime > end_time) {
            this.$message.error("考试已经结束！");
            this.$router.replace('/sessionlist')
            reject(false);
          }

          this.showloadingView = true
          // console.log('1231312312' + result.remain_wait_time)

          // const loading = this.$loading({
          //   lock: true,
          //   text: `需要等待时间${parseInt(result.remain_wait_time / 60)}分钟`,
          //   spinner: "el-icon-loading",
          //   background: "rgba(0, 0, 0, 0.7)",
          // });

          // var diff = 
          var timeCha = result.remain_wait_time
          //timeCha = 0
          this.countDownInternal = setInterval(() => {
            this.showStartBtn = false
            timeCha--
            if (timeCha < 0) {

              var currentTime1 = new Date().getTime() / 1000;

              var diff = end_time - currentTime1;
              var last_time = parseInt(diff);
              this.startCountDown(last_time,test_duration*60);

              clearInterval(this.countDownInternal)
              this.showloadingView = false
              resolve(true);

            }else {
              this.formatShowTime(timeCha)
            }
          }, 1000)

          // setTimeout(() => {
          //   var diff = end_time - currentTime;
          //   var last_time = parseInt(diff);
          //   this.startCountDown(last_time);
          //   //  this.showloadingView = false
          //   // loading.close();
          //   resolve(true);
          // }, result.remain_wait_time * 1000);
        });
      });
    },

    formatShowTime(time) {
      // console.log('倒计时' + time)
      var theTime = time;
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if (theTime > 60) {//超过1分钟
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);//剩余秒数
        if (theTime1 > 60) {//超过60分钟
          theTime2 = parseInt(theTime1 / 60);//多少小时
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      this.hourLab = this.formatTime1(theTime2)
      this.minuteLab = this.formatTime1(theTime1)
      this.secondLab = this.formatTime1(theTime)
    },
    formatTime1(time) {
      if (time >= 10) {
        return time
      }
      return "0" + time
    },

    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId()).then((res) => {
        var result = res.data;

        if (result) {
          this.canExam = false;
          console.log("---sorry--");
          this.clearCountDown();
        }
      });
    },
    doSubmitAnswer() {


      if (this.remainStamp <= 1800 && this.test_type != 'mock_test'){

        this.$message({
          message: '开考后30分钟才允许提前交卷',
          type: "warning",
        });
        // this.$message.error('Submission is only allowed within the last 15 minutes.');
        return
      }

      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.showSubmitAnswerDialog = true;
    },
    handleClose() {
      this.messageDrawer = false;
    },
    showRedMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)

        this.traversalData(res.data.data);
      });
      console.log(11);
    },
    traversalData(newData) {
      var haveNoReply = false;
      var newTip = false;

      for (var i = 0; i < newData.length; i++) {
        var message = newData[i];
        if (message.message_reply_id == "") {
          haveNoReply = true;
        }

        if (i < this.messageList.length) {
          var oldmessage = this.messageList[i];

          if (message.message_reply_id == oldmessage.message_reply_id) {
            console.log("是一致的");
          } else {
            console.log("是不一致的");
            newTip = true;
          }
        }
      }

      console.log("是否需要刷新", haveNoReply);
      console.log("是否需要提示新消息", newTip);

      if (haveNoReply == false) {
        clearInterval(this.timer);
      }

      if (newTip == true) {
        this.showRedTip = true;
      } else {
        this.showRedTip = false;
      }

      this.messageList = newData;
    },
    showMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
      this.messageStr = "";
      this.messageDrawer = true;
      this.showRedTip = false;
      // clearInterval(this.timer);
    },
    getMyMessages() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
    },
    sendMessage() {
      if (this.messageType == "请选择消息类型") {
        this.$message.error("请选择消息类型");
        return;
      }
      if (this.messageStr == "") {
        this.$message.error("请输入内容");
        return;
      }

      sendMessage(
        getUserId(),
        this.messageType,
        "student",
        this.messageStr,
        getProjectCode(),
        this.test_event
      ).then((res) => {
        this.messageStr = "";
        this.messageType = "请选择消息类型";

        this.$message({
          message: "发送成功",
          type: "success",
        });

        this.getMyMessages();
        // this.timer = setInterval(this.showRedMessage, 5000);
      });
    },
    showBigPic(path) {
      this.currentSelectedPicPath = "https://" + path;
      this.showBigPicDialog = true;
    },
    doSelectQuestion(index) {
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
      this.showDrawer = false;
      this.resetViewPosition();
    },
    showExchangeTip(){
        this.$alert('该切屏次数仅为系统智能记录次数，监考人员将根据实时记录的屏幕共享画面进行人工复核。最终切屏记录及是否认定为违规行为以人工复核结果为准。若因为非故意作弊原因（包括弹窗、意外点击屏幕外区域等），可继续正常进行考试', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            
          }
        });
    },
    showMenu() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      var choiceList = [];
      var noChoiceList = [];
      var temp = this.drawerQuestionList;
      var total = temp.length;
      var answered = 0;
      for (var i = 0; i < temp.length; i++) {
        var info = temp[i];
        var tempInfo = {
          question_question: info["question_question"],
          parentIndex: i,
        };
        if (info.choice !== "") {
          answered++;
          choiceList.push(tempInfo);
        } else {
          noChoiceList.push(tempInfo);
        }
      }
      this.drawerAnswerInfo = "已答：" + answered + "/" + total;
      // drawerAnswerInfo
      this.drawerChoiceQuestionList = choiceList;
      this.drawerNoChoiceQuestionList = noChoiceList;
      this.showDrawer = true;
    },
    showPreQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;
      index--;
      if (index < 0) {
        this.$message({
          message: "已经是第一题啦",
          type: "warning",
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
    },
    showNextQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;
      index++;
      if (index >= this.examQuestionSize) {
        this.$message({
          message: "已经是最后一题啦",
          type: "warning",
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
    },
    showPreImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      if (this.$refs.pdfView.checkIsLoadingPdf()){
        return;
      }
      this.resetViewPosition();
      this.currentSelectedImageIndex = this.$refs.pdfView.prePage()

    },
    showNextImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      if (this.$refs.pdfView.checkIsLoadingPdf()){
        return;
      }
      this.resetViewPosition();
      this.currentSelectedImageIndex =  this.$refs.pdfView.nextPage()
    },
    goBack() {
      if (this.forceShutDown) {
        this.$router.replace('/sessionlist')
        return;
      }

      if (this.showAnswerResultClick) {
        this.$router.replace('/sessionlist')
        return;
      }

      if (this.deviceStatus != 0 || !this.canExam) {
        this.$router.replace('/sessionlist')
        return;
      }

      this.$confirm(
        "当前正在进行考试，退出后，考试试卷会自动提交，您确定要退出考试？",
        "提示",
        {
          confirmButtonText: "确定",
          type: "warning",
        }
      )
        .then(() => {
          this.quizTrue();
        })
        .catch(() => {});
      // this.$alert('当前正在进行考试，退出后，考试试卷会自动提交，您确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     // this.$router.go(-1)
      //     this.quizTrue()
      //   }
      // });
    },
    quizTrue() {
      this.$confirm("二次确认：您确定要退出考试？", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.uploadAnswers();
          this.$router.replace('/sessionlist')
        })
        .catch(() => {});

      // this.$alert('确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     this.$router.go(-1)
      //   }
      // });
    },
    fetchData() {
      this.questionAnswers = {}
      var student_id = getUserId();
      getExerciseTemplateByStudent(this.template_id, student_id).then((res) => {
       
        this.section_list_instruction = res.data.data.section_list_instruction
        this.test_subject = res.data.data.test_subject;
        this.exam_images = res.data.data.pic_arr;
        this.examImageSize = this.exam_images.length;
        var index = 0;
        var sections = res.data.data.sections_item;
        this.test_type = res.data.data.test_type;


        var pdf_urls = [];
        //先从DATA获取PDF
        var urls =res.data.data.pic_arr;
        for (var j = 0; j < urls.length; j++) {
          var url = urls[j]
          if (url.indexOf("https://") != -1  || url.indexOf("http://") != -1) {
            pdf_urls.push(url)
          }else{
            pdf_urls.push('https://'+url)
          }
        }


        for (var i = 0; i < sections.length; i++) {
          var section = sections[i];

          if(section['pic_arr']){
            var pic_arr =section['pic_arr']
            for (var k=0;k<pic_arr.length;k++){
              var pic =pic_arr[k]
              if (pic.indexOf("https://") != -1  || pic.indexOf("http://") != -1) {
                pdf_urls.push(pic)
              }else{
                pdf_urls.push('https://'+pic)
              }
            }
            
          }



          var questions_items = section["questions_item"];
          for (var j = 0; j < questions_items.length; j++) {
            index++;
            var pos = index;
            if (pos < 10) {
              pos = "0" + pos;
            }
            var question = {
              question_id: questions_items[j]["_id"]["$id"],
              question_index: pos,
              question_question: questions_items[j]["question_question"],
              question_optionA: questions_items[j]["question_optionA"],
              question_optionB: questions_items[j]["question_optionB"],
              question_optionC: questions_items[j]["question_optionC"],
              question_optionD: questions_items[j]["question_optionD"],
              question_optionE: questions_items[j]["question_optionE"],
              choice: questions_items[j]["user_answer"],
            };
            this.questionList.push(question);
          }
        }
        this.drawerQuestionList = this.questionList;
        this.examQuestionSize = this.questionList.length;
        this.currentSelectedQuestionIndex = 0;
        this.currentSelectedQuestion = this.questionList[0];
        this.scheduleHeartBeat();

        //test
        // this.section_list_instruction  = ''

        if (urls.length <= 0){
            this.havePicUrl = false
          }else{
            if(this.section_list_instruction == ''){
              this.havePicUrl = true
            }
          }
        setTimeout(()=>{
          this.asyncIntPDF(pdf_urls)
        },200)  
      });
    },
    async asyncIntPDF(urls) {
      this.$refs['pdfView'] && await this.$refs['pdfView'].initPdfView(urls)
    },
    startCountDown(seconds,allTime) {
      if (this.countDownThread) {
        return;
      }
      this.remainStamp = allTime - seconds;

      let totalTime = seconds;
      this.countDownThread = window.setInterval(() => {
        this.countDownTimes = this.formatSeconds(totalTime);
        // this.remainStamp = totalTime;
        this.remainStamp ++;
        totalTime--;
        if (totalTime < 0) {
          window.clearInterval(this.countDownThread);
          this.$alert("Time out!", "Tips", {
            confirmButtonText: "confirm",
            callback: () => {
              this.uploadAnswers();
            },
          });
        }
      }, 1000);
    },
    saveAnswer(choice, item) {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      let question_id = item.question_id;
      let index = this.currentSelectedQuestionIndex
      this.questionAnswers[question_id] = {"choice":choice,"status":"no"}
      this.drawerQuestionList[index]["choice"] = choice;
      submitAnswer(
        question_id,
        this.template_id,
        choice,
        this.user_id,
        this.ip,
        this.test_event,
        getProjectCode()
      ).then((res) => {
        if(res.data.code == 0){
          this.questionAnswers[question_id]['status'] ='yes'
        }else{
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
        }
      });
    },
    uploadAnswers() {
      let temp_no_answers= {}
      var questionIds= Object.keys(this.questionAnswers)
      questionIds.forEach((key) =>{
          var question = this.questionAnswers[key]
        if(question['status'] == 'no'){
          temp_no_answers[key] = question['choice']
        }
      })
      // let answerList = this.questionList.map(item => item.choice);
      var project_code = getProjectCode();
      uploadAnswersV3(
        project_code,
        this.user_id,
        this.template_id,
        this.app_id,
        this.ip,
        this.test_event,
        "personal",
          temp_no_answers
      ).then((res) => {
        if (this.currentAudio) {
          this.currentAudio.pause();
        }
        if (res.data.code === 0) {
          this.$message({
            message: "upload success",
            type: "success",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.replace('/sessionlist')
          // this.showAwardDialog(res.data.score);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.replace('/sessionlist')
        }
      });
    },
    showAwardDialog(data) {
      this.answerResultDta = data;
      this.showAnswerResultDialog = true;
    },
    formatSeconds(endTime) {
      let secondTime = parseInt(endTime);
      let min = 0;
      let h = 0;
      let result = "";
      if (secondTime > 60) {
        min = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (min > 60) {
          h = parseInt(min / 60);
          min = parseInt(min % 60);
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    closeView() {
      this.showAnswerResultClick = true;
      this.$router.replace('/sessionlist')
    },
    checkCanExam() {
      if (this.DEBUG_DEV) {
        return true;
      }
      if (this.canExam) {
        return true;
      }
      this.$message.error("检测到监考录像已被中断，您无法在继续考试！");
      return false;
    },
    checkDeviceCanWork() {
      if (this.deviceStatus == 0) {
        return true;
      }
      if (this.deviceStatus == 1) {
        this.$message.error(`您当前浏览器不支持访问摄像头，无法进行考试`);
        return false;
      }
      if (this.deviceStatus == 2) {
        this.$message.error(`您当前浏览器不支持屏幕录制，无法进行考试`);
        return false;
      }
      if (this.deviceStatus == 3) {
        this.$message.error(`您当前设备获取摄像头失败，无法进行考试`);
        return false;
      }
      if (this.deviceStatus == 4) {
        this.$message.error(`您当前设备获取屏幕录制失败，无法进行考试`);
        return false;
      }
      return true;
    },
    resetViewPosition() {
      var exam_image = document.getElementById("exam-image");
      var scrollTop = exam_image.scrollTop;
      scroll(scrollTop, 0, exam_image);
    },
    clearCountDown() {
      this.doUploadHeartBeat(false);
      if (this.heartBeatTimer) {
        window.clearInterval(this.heartBeatTimer);
        this.heartBeatTimer = null;
      }
    },
    refreshVisible() {
      getTestVisibleChangeCount(
        this.template_id,
        getUserId(),
        getProjectCode()
      ).then((res) => {
        let count = res.data.data;
        this.screenChangeCount = count;
      });
    },
    scheduleHeartBeat() {
      this.heartBeatTimer = window.setInterval(() => {
        this.doUploadHeartBeat(true);
      }, 30000);
    },
    doUploadHeartBeat(status) {
      uploadTestHeartBeat(getUserId(), this.template_id, status).then(
        (res) => {}
      );
    },
    invigilateCallback() {},
  },
  beforeDestroy() {
    if (this.countDownThread) {
      window.clearInterval(this.countDownThread);
      this.countDownThread = null;
    }
    if (this.countDownInternal) {
      clearInterval(this.countDownInternal)
      this.countDownInternal = null
    }
    window.removeEventListener("offline", this.networkErrorCallback);
    window.removeEventListener("online", this.networkSuccessCallback);
    window.removeEventListener("popstate", this.goBack, false);
    this.clearCountDown();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.showAnswerResultDialogStyle {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showMenuDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showSubmitAnswerDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style lang="scss">
.exam-btn-qa {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #f28801;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;
}
.exam-btn-exchange-lang {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: #4267c0;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #b1ccff;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 18px;
}

/deep/ .el-tabs__item {
  color: white !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: white !important;
}

.drawer-question-list {
  width: 100%;
  height: 100%;
  background-color: #2d50b0;
  display: flex;
  flex-direction: column;
}

.drawer-answered {
  margin-left: 15px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 80px;
  color: white;
  background-color: #2d50b0;
  border-radius: 17px;
  font-weight: 600;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}

.drawer-question-item-root {
  padding: 15px 15px 0 15px;
  cursor: pointer;
  background: #2d50b0;

  .drawer-question-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    color: white;
    font-size: 20px;
    background: #2d50b0;
    padding: 12px;
    box-sizing: border-box;
    font-family: Montserrat-SemiBold;
    border-radius: 16px;
  }

  .selected {
    color: #2d50b0;
    font-family: Montserrat-SemiBold;
    background: #ffffff;
    border-radius: 16px;
  }
}

.drawer-question-item-root:last-child {
  padding-bottom: 30px;
}

.exam-empty-width-view {
  width: 2.184vw;
  height: 100%;
}

.exam-empty-height-view {
  height: 20px;
  width: 100%;
}

.exam-question-content {
  padding: 12px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.exam-question-content::-webkit-scrollbar {
  display: none;
}

.exam-image {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.exam-image::-webkit-scrollbar {
  display: none;
}

.returnStyle {
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 45%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #2d50b0;
  color: white;
}

.infoStyle {
  display: flex;
  border: 1px solid #2d50b0;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
}

.infoImg {
  width: 14px;
  height: 14px;
}

.box {
  display: flex;
  width: 90%;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}

.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}

.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
}

.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
}

.awardTitle1 {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: left;
  color: #0036a0;
  font-size: 30px;
  margin-top: 60px;
  margin-left: 80px;
}

.scoreLab1 {
  font-family: Montserrat-SemiBold;
  margin-top: 60px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px;
  color: #fd5900;
  float: right;
}

.scoreLab2 {
  font-family: Montserrat-SemiBold;
  margin-top: 65px;
  margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  color: #0f429e;
  font-size: 25px;
  float: right;
}

.awardTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 60px;
  margin-top: 30px;
  padding-top: 10px;
}

.answer-result-root {
  margin: 50px auto;
  padding: 0;
  background-image: url("../../assets/awardDetailBg.png");
  width: 80%;
  height: 70vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}

.menu-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}

.submitAnswer-root {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 27vw;
  height: 59vh;
  background-image: url("../../assets/bg_submit_answer.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  border-radius: 20px;
}

.option {
  cursor: none;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1vw;
  margin-top: 24px;
  border-radius: 10px;
  font-weight: bold;
  color: #072d76;
  font-family: Montserrat-SemiBold;
}

.div_p {
  font-family: Montserrat-SemiBold;
  font-size: 1vw;
  display: inline-block;
  color: #072d76;
}

.questionTitle {
  cursor: none;
  font-family: Montserrat-SemiBold;
  color: #072d76;
  font-size: 1.2vw;
  font-weight: bold;
  text-align: left;
}
.exam-goto-page {
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  width: 200px;
  height: 100%;
  background-color: #00348b;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.exam-menu {
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  width: 140px;
  height: 100%;
  background-color: #00348b;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.exam-page-bottom {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px -1px 24px 1px rgba(0, 14, 138, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 50px;
}

.exam-page-bottom-text {
  cursor: pointer;
  font-size: 1.144vw;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #2d50b0;
}

.exam-content-page {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  border-radius: 16px;
  opacity: 1;
}

.exam-submit {
  margin-right: 30px;
  cursor: pointer;
  color: #e64f29;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: white;
  padding: 6px 14px;
  font-weight: bold;
  border-radius: 4px;
}

.exam-count-down {
  color: white;
  font-weight: bolder;
  font-size: 26px;
  margin-right: 60px;
}

.exam-top-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 176px;
}

.exam-content {
  width: 100%;
  background-image: url("../../assets/magin_right_bg.png");
  flex: 1;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.exam-top {
  height: 60px;
  display: flex;
  background: #e64f29;
  align-items: center;
  justify-content: flex-end;
}

.exam-root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.messageList {
  width: 500px;
  height: 90%;
  left: 5%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.inputStyle {
  width: 100%;
  height: 60px;
}

.inputSelect {
  margin-left: 20px;
  width: 150px;
  height: 40px;
}

.inputStyle1 {
  width: 60%;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
}

.sendStyle {
  margin-left: 10px;
  background-color: #00359f;
  color: white;
  font-family: Montserrat-SemiBold;
}

.messageList {
  width: 90%;
  height: 90%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.messageDetail {
  margin-left: 20px;
  margin-top: 30px;
  width: 90%;
  font-family: Montserrat-SemiBold;
  // height: 50px;
  text-align: left;
}

.studentmessageList {
  width: calc(80% + 30px);
  height: calc(90% - 90px);
  background-color: #072d76;
  margin-top: 30px;
  text-align: left;
}

.redTip {
  position: absolute;
  margin-left: 3vw;
  margin-top: 5px;
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.loadingView{
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 0px;
    left: 0px;
    top: 0px;
    z-index: 999;
    // background-color: red;
}
.loadingImg{
  width: 100%;
  height: 100%;
}
.hourStyle{
  font-size: 50px;
  position: absolute;
  top: 180px;
  left: calc(50% - 250px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;

} 
.minuteStyle{
  font-size: 50px;
  position: absolute;
  top: 180px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;

} 
.secondStyle{
  font-size: 50px;
  position: absolute;
  top: 180px;
  left: calc(50% + 150px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;

}
.dianStyle{
  font-size: 50px;
  position: absolute;
  top: 180px;
  left: calc(50% - 150px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  color: white;
}
.dianStyle1{
  font-size: 50px;
  position: absolute;
  color: white;
  top: 180px;
  left: calc(50% + 50px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
}
.loadingInfoStyle{
  width: 100%;
  position: absolute;
  font-size: 18px;
  color: white;
  top: 330px;
  align-content: center;
  align-items: center;
  text-align: center;
}
.loadingInfoStyleMore{
  width: 50%;
  margin-left: 25%;
  // position: absolute;
  font-size: 18px;
  color: white;
  // top: 350px;
  // align-content: center;
  // align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.exam-content-nourl-page{
  height: calc(100% - 40px);
  overflow-y: auto;
  word-break: normal;
  text-align: left;
  color: #072d76;
  font-size: 15px;
  padding: 10px;
  cursor: none;

}
</style>
